<template>
  <div>
    <!-- 时间范围 -->
    <div>
      <div class="fl time">
        <span>{{ $t("order.transactionTime") }}</span>
        <el-date-picker
          type="date"
          :placeholder="$t('order.selectTime')"
          v-model="start_date"
        >
        </el-date-picker>
        <i class="el-icon-minus i"></i>
        <el-date-picker
          type="date"
          :placeholder="$t('order.endTime')"
          v-model="end_date"
        >
        </el-date-picker>
      </div>
      <div class="fr expenditure">
        <span>{{ $t("financialCenter.expenditure") }} $0 </span>
        <span class="ml">{{ $t("financialCenter.revenue") }} $0</span>
      </div>
      <div class="clear"></div>
    </div>

    <!-- 金额范围 -->
    <div class="mt">
      <div class="fl money">
        <span class="fl">{{ $t("financialCenter.transactionAmount") }}</span>
        <input
          class="fl"
          :placeholder="$t('financialCenter.amountRange')"
          v-model="start_money"
        />
        <i class="el-icon-minus fl i"></i>
        <input
          class="fl"
          :placeholder="$t('financialCenter.amountRange')"
          v-model="end_money"
        />
        <div class="clear"></div>
      </div>
      <div class="btn fr">
        <el-button class="determine">{{ $t("financialCenter.determine") }}</el-button>
        <el-button class="clear">{{ $t("financialCenter.clear") }}</el-button>
      </div>
      <div class="clear"></div>
    </div>

    <!-- 内容 -->
    <div class="ml" style="margin-top:30px">
      <h6>{{ $t("financialCenter.ph") }}</h6>
      <el-empty v-if="true" :description="this.$t('common.emptyTips')"></el-empty>
      <el-card v-else class="card mt">
        <div class="main">
          <img class="fl" src="@/assets/Ellipse_97.png" />
          <p class="p1 fl">Change withdrawal - to China Merchants Bank</p>
          <br />
          <p class="p2 fr">-8.00</p>
          <br />
          <p class="p3 fl">07:00, December 12</p>
          <el-divider class="divider"></el-divider>
        </div>
        <div class="main">
          <img class="fl" src="@/assets/Ellipse_97.png" />
          <p class="p1 fl">Change withdrawal - to China Merchants Bank</p>
          <br />
          <p class="p2 fr">-8.00</p>
          <br />
          <p class="p3 fl">07:00, December 12</p>
          <el-divider class="divider"></el-divider>
        </div>
        <div class="main">
          <img class="fl" src="@/assets/Ellipse_97.png" />
          <p class="p1 fl">Change withdrawal - to China Merchants Bank</p>
          <br />
          <p class="p2 fr">-8.00</p>
          <br />
          <p class="p3 fl">07:00, December 12</p>
          <el-divider class="divider"></el-divider>
        </div>
        <div class="main">
          <img class="fl" src="@/assets/Ellipse_97.png" />
          <p class="p1 fl">Change withdrawal - to China Merchants Bank</p>
          <br />
          <p class="p2 fr">-8.00</p>
          <br />
          <p class="p3 fl">07:00, December 12</p>
          <el-divider class="divider"></el-divider>
        </div>
        <div class="main">
          <img class="fl" src="@/assets/Ellipse_97.png" />
          <p class="p1 fl">Change withdrawal - to China Merchants Bank</p>
          <br />
          <p class="p2 fr">-8.00</p>
          <br />
          <p class="p3 fl">07:00, December 12</p>
          <el-divider class="divider"></el-divider>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      start_date: "",
      end_date: "",
      start_money: "",
      end_money: "",
    };
  },
};
</script>

<style scoped>
.time {
  font-size: 14px;
  font-weight: 700;
  margin-right: 30px;
  margin-left: 20px;
}
.time span {
  margin-right: 10px;
}
.time .i {
  margin-left: 5px;
  margin-right: 5px;
}
.expenditure {
  margin-right: 50px;
}
.expenditure span {
  color: #2c3542;
  font-size: 16px;
  line-height: 40px;
}
.money {
  font-size: 14px;
  font-weight: 700;
  margin-right: 30px;
  margin-left: 20px;
}
.money span {
  margin-right: 10px;
  margin-top: 10px;
}
.money input {
  width: 200px;
  height: 40px;
  outline: none;
  padding-left: 18px;
  border: 1px solid #dcdfe6;
}
.money .i {
  margin-left: 5px;
  margin-top: 15px;
  margin-right: 5px;
}
.btn {
  margin-right: 52px;
}
.btn .determine {
  width: 140px;
  color: #fff;
  border-radius: 6px;
  background: #ff0a0a;
}
.btn .clear {
  width: 140px;
  color: #fff;
  border-radius: 6px;
  margin-left: 30px;
  background: #979797;
}
.main {
  margin-top: 50px;
}
.card img {
  width: 46px;
  height: 46px;
  margin-left: 50px;
  margin-right: 30px;
}
.card .p1,
.p2 {
  font-size: 15px;
  font-weight: 700;
}
.card .p3 {
  font-size: 11px;
  color: gray;
  margin-top: -10px;
}
.divider {
  margin-top: 35px;
}
</style>